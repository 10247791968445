
const visL1CategoriesForSite = {
  MLB: ['MLB1743', 'MLB1540', 'MLB1459'],
  MLA: ['MLA1743', 'MLA1540', 'MLA1459'],
  MLM: ['MLM1743', 'MLM1540', 'MLM1459'],
  MLC: ['MLC1743', 'MLC1540', 'MLC1459'],
  MLU: ['MLU1743', 'MLU1540', 'MLU1459'],
  MPE: ['MPE1743', 'MPE1540', 'MPE1459'],
  MCO: ['MCO1743', 'MCO1540', 'MCO1459'],
  MEC: ['MEC1743', 'MEC1540', 'MEC1459'],
};

/**
 * Service interface
 */
class Service {
  static isVis = (siteId, categoryId, breadcrumbs) => this.#isVisL1Category(siteId, categoryId) || this.#isVisLnCategory(siteId, breadcrumbs);

  static #isVisL1Category = (siteId, categoryId) => {
    const visCategories = visL1CategoriesForSite[siteId];
    if (visCategories.includes(categoryId)) {
      return true;
    }
    return false;
  };

  static #isVisLnCategory = (siteId, breadcrumbs) => {
    if (breadcrumbs) {
      return !!breadcrumbs.find(current => this.#isVisL1Category(siteId, current.id));
    }
    return false;
  };
}
/**
 * Expose Service
 */
module.exports = Service;

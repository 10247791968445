/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const CategoryLeftMenu = require('../components/menu/categoryLeftMenu/categoryLeftMenu');
const SearchEntryCarousel = require('../components/search/searchEntryCarousel/searchEntryCarousel');
const SearchEntryContainer = require('../components/search/searchEntryContainer/searchEntryContainer');
const CategoryNavigationCarousel = require('../components/category/categoryNavigationCarousel/categoryNavigationCarousel');
const deviceTypes = require('../../../enums/deviceTypes');

/**
 * TrendsViewDesktop Component
 */
function TrendsViewDesktop(props) {
  const namespace = 'ui-view-desktop';
  const { siteId, categoryId, higherRevenueTrends, shortTailTrends, increasedSearchGrowthTrends, featuredTrends, consolidatedTrends, navigation, jsDisabled, supportWebP } = props;
  const deviceType = deviceTypes.DESKTOP_TYPE;

  let withLeftMenu = false;
  let categoryLeftMenu = null;
  let categoryNavigationCarousel = null;
  let classNameWithMenu = 'without-menu';

  if (categoryId) {
    withLeftMenu = true;
    categoryLeftMenu = <CategoryLeftMenu navigation={navigation} />;
    classNameWithMenu = 'with-menu';
  } else {
    categoryNavigationCarousel = <CategoryNavigationCarousel siteId={siteId} categories={navigation.categories} jsDisabled={jsDisabled} />;
  }

  const renderSearchEntryCarousel = (trendsData, position) => trendsData.trends.length > 0
  && (
    <SearchEntryCarousel key={`desktop-search-carousel-${position}`} {...trendsData} withLeftMenu={withLeftMenu} deviceType={deviceType} jsDisabled={jsDisabled} supportWebP={supportWebP} />
  );

  const renderTrends = () => {
    if (consolidatedTrends.trends !== undefined) {
      return (
        <SearchEntryContainer {...consolidatedTrends} withLeftMenu={withLeftMenu} deviceType={deviceType} isConsolidateView jsDisabled={jsDisabled} supportWebP={supportWebP} />
      );
    }

    return [
      renderSearchEntryCarousel(increasedSearchGrowthTrends, 0),
      renderSearchEntryCarousel(higherRevenueTrends, 1),
      renderSearchEntryCarousel(shortTailTrends, 2),
    ];
  };

  return (
    <div className={`${namespace}-div-with-category`}>
      {categoryLeftMenu}
      <div className={`${namespace}-${classNameWithMenu}`}>

        {renderTrends()}

        {categoryNavigationCarousel}
        {featuredTrends.trends.length > 0
          && (
          <SearchEntryContainer {...featuredTrends} withLeftMenu={withLeftMenu} deviceType={deviceType} isConsolidateView={false} jsDisabled={jsDisabled} />
          )}
      </div>
    </div>
  );
}

TrendsViewDesktop.propTypes = {
  jsDisabled: PropTypes.bool.isRequired,
  supportWebP: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
  categoryId: PropTypes.string,
  higherRevenueTrends: PropTypes.shape({
    title: PropTypes.string.isRequired,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  shortTailTrends: PropTypes.shape({
    title: PropTypes.string.isRequired,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  increasedSearchGrowthTrends: PropTypes.shape({
    title: PropTypes.string.isRequired,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  featuredTrends: PropTypes.shape({
    title: PropTypes.string.isRequired,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }),
  consolidatedTrends: PropTypes.shape({
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }),
  navigation: PropTypes.shape({
    name: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
    breadcrumb: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
  }),
};

/**
 * Inject i18n context as props into TrendsViewDesktop.
 */
module.exports = injectI18n(TrendsViewDesktop);

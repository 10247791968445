/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const urlUtils = require('../../../../../utils/urlUtils');
const verticalUtils = require('../../../../../utils/verticalUtils');

/**
 * CategoryNavigation Component
 */
function CategoryNavigation(props) {
  const namespace = 'ui-category-navigation';
  const { siteId, id, name, icon, url } = props;

  const finalUrl = (() => {
    if (!verticalUtils.isVis(siteId, id)) {
      return url;
    }
    return urlUtils.getUrlForCategory({ id, name });
  })();

  return (
    <a className={`${namespace}-category category`} href={finalUrl}>
      <div className={`${namespace}-icon`} aria-hidden="true">
        <svg className={`${namespace}-nc-icon nc-icon`} aria-labelledby={id} role="img">
          <title id={id}>{name}</title>
          <use xlinkHref={`#nc-${icon}`} />
        </svg>
      </div>
      <p>{name}</p>
    </a>
  );
}

CategoryNavigation.propTypes = {
  siteId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  url: PropTypes.string,
};

/**
 * Inject i18n context as props into CategoryNavigation.
 */
module.exports = injectI18n(CategoryNavigation);

/**
 * Module dependencies
 */
const React = require('react');
const { useState } = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const ChevronDown = require('@andes/icons/ChevronDown12');
const ChevronUp = require('@andes/icons/ChevronUp12');
const urlUtils = require('../../../../../utils/urlUtils');
/**
 * CategoryTopMenu Component
 */
function CategoryTopMenu(props) {
  const namespace = 'ui-menu-top';
  const { i18n, navigation } = props;

  const [showMenu, setShowMenu] = useState(false);
  const buttonColor = 'gray';
  const trendsText = i18n.gettext('Tendencias en ');

  const renderButtonIcon = () => {
    if (showMenu) {
      return <ChevronUp color={buttonColor} />;
    }

    return <ChevronDown color={buttonColor} />;
  };

  const activeCategoryLevel = navigation && navigation.breadcrumb ? navigation.breadcrumb.length : 0;
  const childrenCategoryLevel = navigation && navigation.breadcrumb ? navigation.breadcrumb.length + 1 : 0;

  const sortedCategories = navigation.categories.sort((a, b) => a.name.localeCompare(b.name));

  const urlWithoutCategory = urlUtils.getUrlWithoutCategory();

  const renderHomeLi = () => {
    const id = 'menu-home-id';
    const homeText = i18n.gettext('Inicio');
    const anchorClass = `${namespace}-content-menu-anchor-not-active ${namespace}-content-menu-level-0`;
    return (
      <li key={id}>
        <a href={urlWithoutCategory} className={anchorClass}> {homeText} </a>
      </li>
    );
  };

  const createUrl = (category) => (urlUtils.getUrlForCategory(category));

  const renderLi = (category, index, isActive) => {
    const url = createUrl(category);
    let anchorClass = isActive ? `${namespace}-content-menu-anchor-active` : `${namespace}-content-menu-anchor-not-active`;
    anchorClass += ` ${namespace}-content-menu-level-${index}`;
    return (
      <li key={category.id} className={isActive ? `${namespace}-content-menu-li-active` : ''}>
        <a href={url} className={anchorClass}> {category.name} </a>
      </li>
    );
  };

  return (
    <section>
      <div className={`${namespace}-content`}>
        <div role="button" className={`${namespace}-toggle-button`} onClick={() => setShowMenu(!showMenu)} tabIndex={0}>
          <span className={`${namespace}-toggle-button-title`}>{trendsText}</span>
          <span className={`${namespace}-toggle-button-category-name`}>{navigation && navigation.name}</span>
          {renderButtonIcon()}
        </div>

        <nav className={`${namespace}-content-menu ${showMenu ? '' : 'not-display'} `}>
          <ol>
            {renderHomeLi()}
            {navigation.breadcrumb && navigation.breadcrumb.map((category, index) => renderLi(category, index)) }
            {renderLi({ name: navigation.name, id: navigation.category_id }, activeCategoryLevel, true)}
          </ol>

          <ol className="children-categories">
            {sortedCategories.map(category => renderLi(category, childrenCategoryLevel)) }
          </ol>
        </nav>
      </div>

    </section>
  );
}

CategoryTopMenu.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  navigation: PropTypes.shape({
    site_id: PropTypes.string.isRequired,
    category_id: PropTypes.string,
    name: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
    breadcrumb: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
  }).isRequired,
};

/**
 * Inject i18n context as props into TrendsViewDesktop.
 */
module.exports = injectI18n(CategoryTopMenu);

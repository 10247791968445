/**
 * Module dependencies
 */
const React = require('react');
const { useState, useEffect } = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const CarouselSnapped = require('@andes/carousel-snapped');
const SearchEntry = require('../searchEntry/searchEntry');
const stringUtils = require('../../../../../utils/stringUtils');
const useWindowDimensions = require('../../../hooks/useWindowDimensions');

const { CarouselSnappedSlide } = CarouselSnapped;

/**
 * SearchEntryCarousel Component
 */
function SearchEntryCarousel(props) {
  const namespace = 'ui-search-carousel';
  const { trends, title, withLeftMenu, deviceType, description, jsDisabled, supportWebP } = props;

  const containerClass = (withLeftMenu ? 'container-with-left-menu' : 'container-without-left-menu');

  const { width } = useWindowDimensions();

  const viewData = withLeftMenu
    ? {
      trendsToViewDefault: 4,
      entryColumnWidth: 300,
      lateralMenuWidth: 300,
      minWidthToShowAllEntries: 1440,
      showOneTrendWhenWidthIsLessThan: 930,
    }
    : {
      trendsToViewDefault: 5,
      entryColumnWidth: 288,
      lateralMenuWidth: 0,
      minWidthToShowAllEntries: 1350,
      showOneTrendWhenWidthIsLessThan: 615,
    };

  const [trendsToView, setTrendsToView] = useState(viewData.trendsToViewDefault);
  const [showCarousel, setShowCarousel] = useState(jsDisabled);

  useEffect(() => {
    if (width === undefined) {
      return;
    }

    if (width >= viewData.minWidthToShowAllEntries) {
      if (trendsToView !== viewData.trendsToViewDefault) {
        setTrendsToView(viewData.trendsToViewDefault);
      }
    } else if (width < viewData.showOneTrendWhenWidthIsLessThan) {
      setTrendsToView(1);
    } else {
      const newCount = Math.floor((width - viewData.lateralMenuWidth) / viewData.entryColumnWidth);
      if (trendsToView !== newCount) {
        setTrendsToView(newCount);
      }
    }
    setShowCarousel(true);
  }, [width]);

  const titleNode = <h2>{title}</h2>;
  const titleKey = stringUtils.replaceBlanksWithSlash(title);
  trends.forEach((trend, index) => {
    trend.id = `${titleKey}-${index}`;
  });

  if (!showCarousel) {
    const loadingClass = withLeftMenu ? `${namespace}-with-left-menu-loading` : `${namespace}-loading`;
    return <section className={loadingClass} />;
  }
  return (
    <section className={`${namespace}`}>
      <div className={`${containerClass} container`}>
        <div className={`${namespace}-wrapper`}>
          <CarouselSnapped
            pagination={{
              mode: 'light',
              position: 'top',
            }}
            title={titleNode}
            lazyload
            slidesPerView={trendsToView}
            className={trends.length < viewData.trendsToViewDefault ? 'one-page-search-entry-available' : ''}
          >
            {trends.map((searchEntry, index) => (
              <CarouselSnappedSlide key={`${searchEntry.id}-slide`}>
                <div className="entry-column">
                  <SearchEntry
                    key={searchEntry.id}
                    searchEntry={searchEntry}
                    position={index}
                    deviceType={deviceType}
                    description={description}
                    insideCarousel
                    jsDisabled={jsDisabled}
                    supportWebP={supportWebP}
                    onlyForTestSlidesPerView={trendsToView}
                  />
                </div>
              </CarouselSnappedSlide>))}
          </CarouselSnapped>
        </div>
      </div>
    </section>
  );
}

SearchEntryCarousel.propTypes = {
  jsDisabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  withLeftMenu: PropTypes.bool.isRequired,
  trends: PropTypes.arrayOf(PropTypes.shape({
    keyword: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    category_id: PropTypes.string.isRequired,
    site_id: PropTypes.string.isRequired,
  })),
  deviceType: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  supportWebP: PropTypes.bool.isRequired,
};

/**
 * Inject i18n context as props into SearchEntryCarousel.
 */
module.exports = injectI18n(SearchEntryCarousel);

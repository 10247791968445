/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const CategoryTopMenu = require('../components/menu/categoryTopMenu/categoryTopMenu');
const SearchEntryContainerExpandable = require('../components/search/searchEntryContainerExpandable/searchEntryContainerExpandable');
const SearchEntryContainer = require('../components/search/searchEntryContainer/searchEntryContainer');
const CategoryNavigationContainerExpandable = require('../components/category/categoryNavigationContainerExpandable/categoryNavigationContainerExpandable');
const deviceTypes = require('../../../enums/deviceTypes');
/**
 * TrendsViewMobile Component
 */
function TrendsViewMobile(props) {
  const { siteId, categoryId, higherRevenueTrends, shortTailTrends, increasedSearchGrowthTrends, featuredTrends, consolidatedTrends, navigation, jsDisabled, supportWebP } = props;
  const namespace = 'ui-view-mobile';
  const deviceType = deviceTypes.MOBILE_TYPE;

  const renderSearchEntryContainerExpandable = (trendsData, position, preload) => trendsData.trends.length > 0
      && (
      <div className={`${namespace}-search-container-expandable`} key={`${namespace}-search-container-${position}`}>
        <SearchEntryContainerExpandable {...trendsData} jsDisabled={jsDisabled} supportWebP={supportWebP} preload={preload} />
      </div>
      );

  const renderTrends = () => {
    if (consolidatedTrends.trends !== undefined) {
      return (
        <div className={`${namespace}-search-container-consolidated`}>
          <SearchEntryContainer {...consolidatedTrends} deviceType={deviceType} isConsolidateView jsDisabled={jsDisabled} supportWebP={supportWebP} />
        </div>

      );
    }

    return [
      renderSearchEntryContainerExpandable(increasedSearchGrowthTrends, 0, true),
      renderSearchEntryContainerExpandable(higherRevenueTrends, 1, false),
      renderSearchEntryContainerExpandable(shortTailTrends, 2, false),
    ];
  };

  return (
    <div className={`${namespace}-container`}>

      {categoryId
        && (
        <div className={`${namespace}-top-menu`}>
          <CategoryTopMenu navigation={navigation} />
        </div>
        )}

      {renderTrends()}

      {categoryId === undefined
        && (
        <div className={`${namespace}-category-container`}>
          <CategoryNavigationContainerExpandable categories={navigation.categories} siteId={siteId} />
        </div>
        )}

      {featuredTrends.trends.length > 0
        && (
        <div className={`${namespace}-search-container`}>
          <SearchEntryContainer {...featuredTrends} deviceType={deviceType} isConsolidateView={false} />
        </div>
        )}
    </div>
  );
}

TrendsViewMobile.propTypes = {
  supportWebP: PropTypes.bool.isRequired,
  jsDisabled: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
  categoryId: PropTypes.string,
  higherRevenueTrends: PropTypes.shape({
    title: PropTypes.string.isRequired,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  shortTailTrends: PropTypes.shape({
    title: PropTypes.string.isRequired,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  increasedSearchGrowthTrends: PropTypes.shape({
    title: PropTypes.string.isRequired,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  featuredTrends: PropTypes.shape({
    title: PropTypes.string.isRequired,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }),
  consolidatedTrends: PropTypes.shape({
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }),
  navigation: PropTypes.shape({
    name: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
    breadcrumb: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
  }),
};

/**
 * Inject i18n context as props into TrendsViewDesktop.
 */
module.exports = injectI18n(TrendsViewMobile);

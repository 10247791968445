/**
 * Module dependencies
 */
const React = require('react');
const { useState, useEffect } = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const CarouselSnapped = require('@andes/carousel-snapped');
const CategoryNavigation = require('../categoryNavigation/categoryNavigation');
const IconsConfig = require('../icons-config');
const DivideByGroupsOf = require('../../../../../utils/divide-groups');
const CsvIcons = require('../nc-icons.svg');
const urlUtils = require('../../../../../utils/urlUtils');
const useWindowDimensions = require('../../../hooks/useWindowDimensions');

const { CarouselSnappedSlide } = CarouselSnapped;

/**
 * CategoryNavigationCarousel Component
 */
function CategoryNavigationCarousel(props) {
  const namespace = 'ui-category-navigation-carousel';
  const { i18n, siteId, categories, jsDisabled } = props;

  const title = i18n.gettext('Ver por categorías');
  const titleNode = <h2>{title}</h2>;

  const { width } = useWindowDimensions();

  const categoriesByColumn = 2;

  const maxCategoriesByRow = 7;
  const categoryColumnWidth = 170;
  const minWidthToShowAllEntries = 1200;

  const [categoriesByRow, setCategoriesByRow] = useState(maxCategoriesByRow);
  const [showCarousel, setShowCarousel] = useState(jsDisabled);

  useEffect(() => {
    if (width === undefined) {
      return;
    }
    if (width > minWidthToShowAllEntries) {
      if (categoriesByRow !== maxCategoriesByRow) {
        setCategoriesByRow(maxCategoriesByRow);
      }
    } else {
      const newCount = Math.floor(width / categoryColumnWidth);
      if (categoriesByRow !== newCount) {
        setCategoriesByRow(newCount);
      }
    }
    setShowCarousel(true);
  }, [width]);

  const targetUrl = (category) => (urlUtils.getUrlForCategory(category));

  categories.forEach((category) => {
    category.icon = IconsConfig[siteId][category.id] || category.id;
    category.targetUrl = targetUrl(category);
  });
  const items = DivideByGroupsOf(categories, categoriesByColumn);

  if (!showCarousel) {
    const loadingClass = `${namespace}-loading`;
    return <section className={loadingClass} />;
  }
  return (
    <section className={`${namespace}`}>
      <CsvIcons className="categories-cvs-icons" />
      <div className="container">
        <div className="wrapper">
          <CarouselSnapped
            pagination={{
              mode: 'light',
              position: 'top',
            }}
            slidesPerView={categoriesByRow}
            title={titleNode}
            spacing={0}
          >
            {items.map(({ elements, id }) => (
              <CarouselSnappedSlide key={`category-carousel-slide-${id}`}>
                <div className="category-column">
                  {elements.map(element => <CategoryNavigation key={element.id} {...element} siteId={siteId} onlyForTestSlidesPerView={categoriesByRow} />)}
                </div>
              </CarouselSnappedSlide>))}
          </CarouselSnapped>
        </div>
      </div>
    </section>
  );
}

CategoryNavigationCarousel.propTypes = {
  jsDisabled: PropTypes.bool.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
};

/**
 * Inject i18n context as props into CategoryNavigationCarousel.
 */
module.exports = injectI18n(CategoryNavigationCarousel);

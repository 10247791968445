/**
 * Module dependencies
 */
const React = require('react');
const { useState } = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const ChevronDown20 = require('@andes/icons/ChevronDown20');
const ChevronUp20 = require('@andes/icons/ChevronUp20');
const CategoryNavigation = require('../categoryNavigation/categoryNavigation');
const IconsConfig = require('../icons-config');
const CsvIcons = require('../nc-icons.svg');
const urlUtils = require('../../../../../utils/urlUtils');

/**
 * CategoryNavigationContainerExpandable Component
 */
function CategoryNavigationContainerExpandable(props) {
  const namespace = 'ui-category-navigationContainerExpandable';
  const { i18n, siteId, categories } = props;
  const countCategoriesShowedByDefault = 6;
  const buttonColor = '#3483FA';

  const title = i18n.gettext('Ver por categorías');
  const moreCategoriesText = i18n.gettext('Ver más');
  const lessCategoriesText = i18n.gettext('Ver menos');

  const [showAllCategories, setShowAllCategories] = useState(false);

  const targetUrl = (category) => (urlUtils.getUrlForCategory(category));

  categories.forEach((category) => {
    category.icon = IconsConfig[siteId][category.id] || category.id;
    category.targetUrl = targetUrl(category);
  });

  const renderCategoryNavigationContent = (category, categoryClassName) => (
    <div key={category.id} className={`category-wrapper ${categoryClassName}`}>
      <CategoryNavigation key={category.id} {...category} siteId={siteId} />
    </div>
  );

  const renderCategoryNavigation = (category, index) => {
    const className = showAllCategories || index < countCategoriesShowedByDefault ? '' : 'not-display';
    return renderCategoryNavigationContent(category, className);
  };

  const handleClick = () => {
    setShowAllCategories(!showAllCategories);
  };

  const renderButtonContent = () => {
    if (showAllCategories) {
      return (
        <>
          {lessCategoriesText}
          <ChevronUp20 color={buttonColor} />
        </>
      );
    }

    return (
      <>
        {moreCategoriesText}
        <ChevronDown20 color={buttonColor} />
      </>
    );
  };

  return (
    <section className={`${namespace}`}>
      <CsvIcons className="not-display" />
      <div className="container">
        <div className="section-header">
          <h2>{title}</h2>
        </div>
        <div className="wrapper">
          {categories.map((category, index) => (
            renderCategoryNavigation(category, index)
          ))}
        </div>
        <button className="section-button" onClick={handleClick}>
          {renderButtonContent()}
        </button>
      </div>
    </section>
  );
}

CategoryNavigationContainerExpandable.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  siteId: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  })).isRequired,
};

/**
 * Inject i18n context as props into CategoryNavigationContainerExpandable.
 */
module.exports = injectI18n(CategoryNavigationContainerExpandable);

/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const SearchEntry = require('../searchEntry/searchEntry');
const deviceTypes = require('../../../../../enums/deviceTypes');
/**
 * SearchEntryContainer Component
 */
function SearchEntryContainer(props) {
  const namespace = 'ui-search-container';
  const { trends, title, withLeftMenu, deviceType, isConsolidateView, supportWebP, jsDisabled } = props;

  const desktopWithoutMenuClass = 'desktop-without-menu';
  const desktopWithMenuClass = 'desktop-with-menu';
  const mobileClass = 'mobile';

  const consolidateViewClass = 'consolidate';
  const featuredViewClass = 'featured';
  const viewTypeClass = isConsolidateView ? consolidateViewClass : featuredViewClass;

  const containerClass = (() => {
    if (deviceType === deviceTypes.MOBILE_TYPE) {
      return mobileClass;
    }

    return withLeftMenu ? desktopWithMenuClass : desktopWithoutMenuClass;
  })();

  return (
    <section className={`${namespace}`}>
      <div className={`${namespace}-${containerClass}`}>
        {isConsolidateView
          ? null
          : <h2>{title}</h2>}
        <div className={`${namespace}-searches ${viewTypeClass}`}>
          {trends.map((searchEntry, index) => (
            <SearchEntry
              key={`search-entry-container-${consolidateViewClass}-${searchEntry.keyword}`}
              searchEntry={searchEntry}
              position={index}
              deviceType={deviceType}
              supportWebP={supportWebP}
              preload
              jsDisabled={jsDisabled}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

SearchEntryContainer.propTypes = {
  title: PropTypes.string,
  withLeftMenu: PropTypes.bool,
  trends: PropTypes.arrayOf(PropTypes.shape({
    keyword: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    category_id: PropTypes.string,
    site_id: PropTypes.string.isRequired,
  })).isRequired,
  deviceType: PropTypes.string.isRequired,
  isConsolidateView: PropTypes.bool.isRequired,
  jsDisabled: PropTypes.bool,
  supportWebP: PropTypes.bool.isRequired,
};

/**
 * Inject i18n context as props into SearchEntryContainer.
 */
module.exports = injectI18n(SearchEntryContainer);


const getKeywordList = (trends) => trends.map(trend => ({
  keyword: trend.keyword,
  category_id: trend.category_id,
}));

const createEventDataTrends = ({ categoryId, increasedSearchGrowthTrends, higherRevenueTrends, shortTailTrends, featuredTrends, navigation }) => {
  const childrenCategories = navigation && navigation.categories ? navigation.categories.map(category => (category.id)) : [];
  const categoryPath = navigation && navigation.breadcrumb ? navigation.breadcrumb.map(category => (category.id)) : [];
  const featured = featuredTrends.trends.map(featuredTrend => featuredTrend.keyword);
  const response = {
    category_id: categoryId,
    most_growth: getKeywordList(increasedSearchGrowthTrends.trends),
    most_desired: getKeywordList(higherRevenueTrends.trends),
    most_popular: getKeywordList(shortTailTrends.trends),
    featured_trends: featured,
    children_categories: childrenCategories,
    category_path: categoryPath,
  };

  if (categoryId === undefined) {
    delete response.category_id;
  }
  return response;
};

const createMeliDataTracks = (props) => {
  const path = '/trends';
  const eventData = createEventDataTrends(props);
  return {
    path,
    event_data: eventData,
  };
};

module.exports = { createMeliDataTracks };

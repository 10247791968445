/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const Breadcrumb = require('@andes/breadcrumb');
const urlUtils = require('../../../../../utils/urlUtils');

const { BreadcrumbLevel } = Breadcrumb;

/**
 * CategoryLeftMenu Component
 */
function CategoryLeftMenu(props) {
  const namespace = 'ui-menu-left';
  const { i18n, navigation } = props;

  const trendsText = i18n.gettext('Tendencias');
  const trendsInText = i18n.gettext('Tendencias en ');
  const forCategoriesText = i18n.gettext('Por categorías');

  const urlForNavigationAndLnCategory = (category) => (urlUtils.getUrlForCategory(category));

  const showBreadcrumb = () => (navigation && navigation.breadcrumb);

  const levelsWithoutTrendsLabel = navigation.breadcrumb.map(category => ({
    disabled: false,
    text: category.name,
    href: urlForNavigationAndLnCategory(category),
    title: category.name,
    id: category.id,
  }));

  const urlWithoutCategory = urlUtils.getUrlWithoutCategory();

  const trendItemMenu = { disabled: false, text: trendsText, href: urlWithoutCategory, id: 'home-menu-link' };

  const currentCategory = { disabled: true, text: navigation.name, id: 'current-category-menu-link' };

  const levels = [trendItemMenu, ...levelsWithoutTrendsLabel, currentCategory];

  const breadcrumbWithLevels = (
    <Breadcrumb className={`${namespace}-breadcrumb`} srLabel="Breadcrumb">
      {levels.map(({ id, text, href, disabled, title }) => (
        <BreadcrumbLevel
          key={id}
          id={id}
          text={text}
          title={title}
          href={href}
          disabled={disabled}
        />
      ))}
    </Breadcrumb>
  );

  const breadcrumb = () => (showBreadcrumb() ? (breadcrumbWithLevels) : null);

  const showChildrenCategories = () => navigation && navigation.categories && navigation.categories.length > 0;

  const sortedCategories = navigation.categories.sort((a, b) => a.name.localeCompare(b.name));

  const childrenCategories = () => {
    if (showChildrenCategories()) {
      return (
        <>
          <div className={`${namespace}-categories-title`}>{forCategoriesText}</div>
          <ul className={`${namespace}-ul`}>
            {
              sortedCategories.map(category => (
                <li className={`${namespace}-li`} key={`${namespace}-li-${category.id}`}>
                  <a href={urlForNavigationAndLnCategory(category)} className={`${namespace}-li-a`}>{category.name}</a>
                </li>))
            }
          </ul>
        </>
      );
    }
    return null;
  };

  return (
    <div className={`${namespace}-main`}>
      {breadcrumb()}
      <div className={`${namespace}-category`}>{trendsInText} {navigation && navigation.name}</div>
      {childrenCategories()}
    </div>
  );
}

CategoryLeftMenu.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  navigation: PropTypes.shape({
    site_id: PropTypes.string.isRequired,
    category_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
    breadcrumb: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
  }),
};

/**
 * Inject i18n context as props into CategoryLeftMenu.
 */
module.exports = injectI18n(CategoryLeftMenu);

module.exports = {
  MBO: {
    MBO1000: 'audio-92',
    MBO1039: 'camera-20',
    MBO1051: 'mobile-camera',
    MBO1132: 'bear-2',
    MBO1144: 'controller-2',
    MBO1168: 'note-03',
    MBO1182: 'guitar',
    MBO1276: 'ball-soccer',
    MBO1367: 'palette',
    MBO1430: 'tshirt-53',
    MBO1459: 'building',
    MBO1540: 'multiple-11',
    MBO1574: 'oven',
    MBO1648: 'tv-2',
    MBO1743: 'car-front',
    MBO1747: 'steering-wheel',
    MBO1798: 'disk',
    MBO1953: 'microsoft',
    MBO3025: 'book-open',
    MBO3937: 'watch-circle',
    MBO1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
  },
  MCO: {
    MCO1000: 'audio-92',
    MCO1039: 'camera-20',
    MCO1051: 'mobile-camera',
    MCO1071: 'food-dog',
    MCO1132: 'bear-2',
    MCO1144: 'controller-2',
    MCO1168: 'note-03',
    MCO1182: 'guitar',
    MCO1246: 'makeup',
    MCO1276: 'ball-soccer',
    MEC1368: 'palette',
    MEC1367: 'hourglass',
    MCO1384: 'baby',
    MCO1430: 'tshirt-53',
    MCO1459: 'building',
    MCO1499: 'chair',
    MCO1540: 'multiple-11',
    MCO1574: 'armchair',
    MCO1648: 'tv-2',
    MCO1743: 'car-front',
    MCO1747: 'steering-wheel',
    MCO175794: 'settings-tool-67',
    MCO1798: 'disk',
    MCO1953: 'microsoft',
    MCO3025: 'book-open',
    MCO3937: 'watch-circle',
    MCO40433: 'ticket-75',
    MCO5726: 'oven',
    MCO1403: 'chef-hat',
    MCO180800: 'hospital-34',
    MCO118204: 'baloon',
    EXTRA_SUPERMARKET: 'supermarket',
    MCO1367: 'hourglass',
    MCO1368: 'palette',
    MCO441917: 'tractor',
    MCO172890: 'construction',
  },
  MCR: {
    MCR1000: 'audio-92',
    MCR1039: 'camera-20',
    MCR1051: 'mobile-camera',
    MCR1071: 'food-dog',
    MCR1132: 'bear-2',
    MCR1144: 'controller-2',
    MCR1168: 'note-03',
    MCR117005: 'settings-tool-67',
    MCR1182: 'guitar',
    MCR1246: 'makeup',
    MCR1276: 'ball-soccer',
    MCR1367: 'palette',
    MCR1384: 'baby',
    MCR1430: 'tshirt-53',
    MCR1459: 'building',
    MCR1499: 'chair',
    MCR1540: 'multiple-11',
    MCR1574: 'armchair',
    MCR1575: 'oven',
    MCR1648: 'tv-2',
    MCR1743: 'car-front',
    MCR1747: 'steering-wheel',
    MCR1798: 'disk',
    MCR1953: 'microsoft',
    MCR3025: 'book-open',
    MCR3937: 'watch-circle',
    MCR1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
    MCR1500: 'construction',
  },
  MCU: {
    MCU1000: 'audio-92',
    MCU1039: 'camera-20',
    MCU1051: 'mobile-camera',
    MCU1132: 'bear-2',
    MCU1144: 'controller-2',
    MCU1168: 'note-03',
    MCU1182: 'guitar',
    MCU1276: 'ball-soccer',
    MCU1367: 'palette',
    MCU1430: 'tshirt-53',
    MCU1459: 'building',
    MCU1540: 'multiple-11',
    MCU1574: 'oven',
    MCU1648: 'tv-2',
    MCU1743: 'car-front',
    MCU1747: 'steering-wheel',
    MCU1798: 'disk',
    MCU1953: 'microsoft',
    MCU3025: 'book-open',
    MCU3937: 'watch-circle',
    EXTRA_SUPERMARKET: 'supermarket',
  },
  MEC: {
    MEC1000: 'audio-92',
    MEC1039: 'camera-20',
    MEC1051: 'mobile-camera',
    MEC1071: 'food-dog',
    MEC1132: 'bear-2',
    MEC1144: 'controller-2',
    MEC1168: 'note-03',
    MEC1182: 'guitar',
    MEC1246: 'makeup',
    MEC1276: 'ball-soccer',
    MEC1368: 'palette',
    MEC1367: 'hourglass',
    MEC1384: 'baby',
    MEC1403: 'chef-hat',
    MEC1430: 'tshirt-53',
    MEC1459: 'building',
    MEC1499: 'chair',
    MEC1540: 'multiple-11',
    MEC1574: 'armchair',
    MEC1648: 'tv-2',
    MEC1743: 'car-front',
    MEC1798: 'disk',
    MEC1953: 'microsoft',
    MEC3937: 'watch-circle',
    MEC5725: 'steering-wheel',
    MEC409431: 'hospital-34',
    MEC435280: 'baloon',
    MEC1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
    MEC454381: 'construction',
    MEC454289: 'settings-tool-67',
  },
  MGT: {
    MGT1000: 'audio-92',
    MGT1039: 'camera-20',
    MGT1051: 'mobile-camera',
    MGT1132: 'bear-2',
    MGT1144: 'controller-2',
    MGT1168: 'note-03',
    MGT1182: 'guitar',
    MGT1276: 'ball-soccer',
    MGT1367: 'palette',
    MGT1430: 'tshirt-53',
    MGT1459: 'building',
    MGT1540: 'multiple-11',
    MGT1574: 'oven',
    MGT1648: 'tv-2',
    MGT1743: 'car-front',
    MGT1747: 'steering-wheel',
    MGT1798: 'disk',
    MGT1953: 'microsoft',
    MGT3025: 'book-open',
    MGT3937: 'watch-circle',
    MGT1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
  },
  MHN: {
    MHN1000: 'audio-92',
    MHN1039: 'camera-20',
    MHN1051: 'mobile-camera',
    MHN1132: 'bear-2',
    MHN1144: 'controller-2',
    MHN1168: 'note-03',
    MHN1182: 'guitar',
    MHN1276: 'ball-soccer',
    MHN1367: 'palette',
    MHN1430: 'tshirt-53',
    MHN1459: 'building',
    MHN1540: 'multiple-11',
    MHN1574: 'oven',
    MHN1648: 'tv-2',
    MHN1743: 'car-front',
    MHN1747: 'steering-wheel',
    MHN1798: 'disk',
    MHN1953: 'microsoft',
    MHN3025: 'book-open',
    MHN3937: 'watch-circle',
    MHN1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
  },
  MLA: {
    MLA1000: 'audio-92',
    MLA1039: 'camera-20',
    MLA1051: 'mobile-camera',
    MLA1071: 'food-dog',
    MLA1132: 'bear-2',
    MLA1144: 'controller-2',
    MLA1168: 'note-03',
    MLA1182: 'guitar',
    MLA1246: 'makeup',
    MLA1276: 'ball-soccer',
    MLA1367: 'hourglass',
    MLA1368: 'palette',
    MLA1384: 'baby',
    MLA1403: 'chef-hat',
    MLA1430: 'tshirt-53',
    MLA1459: 'building',
    MLA1499: 'chair',
    MLA1540: 'multiple-11',
    MLA1574: 'armchair',
    MLA1648: 'tv-2',
    MLA1743: 'car-front',
    MLA1798: 'disk',
    MLA1953: 'microsoft',
    MLA2547: 'ticket-75',
    MLA3025: 'book-open',
    MLA3937: 'watch-circle',
    MLA407134: 'settings-tool-67',
    MLA409431: 'hospital-34',
    MLA5725: 'steering-wheel',
    MLA5726: 'oven',
    MLA9304: 'baloon',
    EXTRA_SUPERMARKET: 'supermarket',
    MLA1512: 'tractor',
    MLA1500: 'construction',
  },
  MLB: {
    MLB1000: 'audio-92',
    MLB1039: 'camera-20',
    MLB1051: 'mobile-camera',
    MLB1071: 'food-dog',
    MLB1132: 'bear-2',
    MLB1144: 'controller-2',
    MLB1168: 'note-03',
    MLB1182: 'guitar',
    MLB1196: 'book-open',
    MLB1246: 'makeup',
    MLB1276: 'ball-soccer',
    MLB1367: 'hourglass',
    MLB1368: 'palette',
    MLB1384: 'baby',
    MLB1403: 'chef-hat',
    MLB1430: 'tshirt-53',
    MLB1459: 'building',
    MLB1499: 'factory',
    MLB1540: 'multiple-11',
    MLB1574: 'armchair',
    MLB1648: 'tv-2',
    MLB1743: 'car-front',
    MLB1798: 'disk',
    MLB1953: 'microsoft',
    MLB218519: 'ticket-75',
    MLB263532: 'settings-tool-67',
    MLB264586: 'hospital-34',
    MLB3281: 'video',
    MLB3937: 'watch-circle',
    MLB5672: 'steering-wheel',
    MLB5726: 'oven',
    EXTRA_SUPERMARKET: 'supermarket',
    MLB12404: 'baloon',
    MLB271599: 'tractor',
    MLB1500: 'construction',
  },
  MLC: {
    MLC1000: 'audio-92',
    MLC1039: 'camera-20',
    MLC1051: 'mobile-camera',
    MLC1071: 'food-dog',
    MLC110931: 'ticket-75',
    MLC1132: 'bear-2',
    MLC1144: 'controller-2',
    MLC1168: 'note-03',
    MLC1182: 'guitar',
    MLC1246: 'makeup',
    MLC1276: 'ball-soccer',
    MLC1367: 'hourglass',
    MLC1368: 'palette',
    MLC1384: 'baby',
    MLC1430: 'tshirt-53',
    MLC1459: 'building',
    MLC1499: 'chair',
    MLC1540: 'multiple-11',
    MLC1574: 'armchair',
    MLC1648: 'tv-2',
    MLC1743: 'car-front',
    MLC1747: 'steering-wheel',
    MLC178483: 'settings-tool-67',
    MLC1798: 'disk',
    MLC1953: 'microsoft',
    MLC3025: 'book-open',
    MLC3937: 'watch-circle',
    MLC5726: 'oven',
    MLC1403: 'chef-hat',
    MLC409431: 'hospital-34',
    MLC435280: 'baloon',
    MLC1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
    MLC1500: 'construction',
  },
  MLM: {
    MLM1000: 'audio-92',
    MLM1039: 'camera-20',
    MLM1051: 'mobile-camera',
    MLM1071: 'food-dog',
    MLM1132: 'bear-2',
    MLM1144: 'controller-2',
    MLM1168: 'note-03',
    MLM1182: 'guitar',
    MLM1246: 'makeup',
    MLM1276: 'ball-soccer',
    MLM1367: 'hourglass',
    MLM1384: 'baby',
    MLM1403: 'chef-hat',
    MLM1430: 'tshirt-53',
    MLM1459: 'building',
    MLM1499: 'chair',
    MLM1540: 'multiple-11',
    MLM1574: 'armchair',
    MLM1575: 'oven',
    MLM1648: 'tv-2',
    MLM1743: 'car-front',
    MLM1747: 'steering-wheel',
    MLM1798: 'disk',
    MLM186863: 'settings-tool-67',
    MLM187772: 'hospital-34',
    MLM1953: 'microsoft',
    MLM3025: 'book-open',
    MLM3937: 'watch-circle',
    MLM44011: 'baloon',
    MLM1368: 'palette',
    EXTRA_SUPERMARKET: 'supermarket',
    MLM189530: 'tractor',
    MLM1500: 'construction',
  },
  MLU: {
    MLU1000: 'audio-92',
    MLU1039: 'camera-20',
    MLU1051: 'mobile-camera',
    MLU1071: 'food-dog',
    MLU1132: 'bear-2',
    MLU1144: 'controller-2',
    MLU1168: 'note-03',
    MLU1182: 'guitar',
    MLU1246: 'makeup',
    MLU1276: 'ball-soccer',
    MLU1384: 'baby',
    MLU1403: 'chef-hat',
    MLU1430: 'tshirt-53',
    MLU1459: 'building',
    MLU1499: 'chair',
    MLU1540: 'multiple-11',
    MLU1574: 'armchair',
    MLU1648: 'tv-2',
    MLU1743: 'car-front',
    MLU1798: 'disk',
    MLU1953: 'microsoft',
    MLU208736: 'settings-tool-67',
    MLU3937: 'watch-circle',
    MLU5725: 'steering-wheel',
    MLU5726: 'oven',
    MLU1512: 'tractor',
    MLU111079: 'baloon',
    EXTRA_SUPERMARKET: 'supermarket',
    MLU442392: 'palette',
    MLU1367: 'hourglass',
    MLU409431: 'hospital-34',
    MLU442458: 'book-open',
    MLU1500: 'construction',
  },
  MLV: {
    MLV1000: 'audio-92',
    MLV1039: 'camera-20',
    MLV1051: 'mobile-camera',
    MLV1071: 'food-dog',
    MLV1132: 'bear-2',
    MLV1144: 'controller-2',
    MLV1182: 'guitar',
    MLV1246: 'makeup',
    MLV1276: 'ball-soccer',
    MLV1384: 'baby',
    MLV1430: 'tshirt-53',
    MLV1459: 'building',
    MLV1499: 'factory',
    MLV1540: 'multiple-11',
    MLV1574: 'armchair',
    MLV1648: 'tv-2',
    MLV1743: 'car-front',
    MLV1747: 'steering-wheel',
    MLV1953: 'microsoft',
    MLV3025: 'book-open',
    MLV3937: 'watch-circle',
    MLV5726: 'oven',
    MLV442454: 'tractor',
    MLV122664: 'chef-hat',
    MLV44011: 'baloon',
    MLV1367: 'hourglass',
    MLV445811: 'palette',
    MLV1176: 'note-03',
    MLV424985: 'settings-tool-67',
    EXTRA_SUPERMARKET: 'supermarket',
    MLV1500: 'construction',
  },
  MNI: {
    MNI1000: 'audio-92',
    MNI1039: 'camera-20',
    MNI1051: 'mobile-camera',
    MNI1132: 'bear-2',
    MNI1144: 'controller-2',
    MNI1168: 'note-03',
    MNI1182: 'guitar',
    MNI1276: 'ball-soccer',
    MNI1367: 'palette',
    MNI1430: 'tshirt-53',
    MNI1459: 'building',
    MNI1540: 'multiple-11',
    MNI1574: 'oven',
    MNI1648: 'tv-2',
    MNI1743: 'car-front',
    MNI1747: 'steering-wheel',
    MNI1798: 'disk',
    MNI1953: 'microsoft',
    MNI3025: 'book-open',
    MNI3937: 'watch-circle',
    MNI1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
  },
  MPA: {
    MPA1000: 'audio-92',
    MPA1039: 'camera-20',
    MPA1051: 'mobile-camera',
    MPA1132: 'bear-2',
    MPA1144: 'controller-2',
    MPA1168: 'note-03',
    MPA1182: 'guitar',
    MPA1276: 'ball-soccer',
    MPA1367: 'palette',
    MPA1430: 'tshirt-53',
    MPA1459: 'building',
    MPA1540: 'multiple-11',
    MPA1574: 'oven',
    MPA1648: 'tv-2',
    MPA1743: 'car-front',
    MPA1747: 'steering-wheel',
    MPA1798: 'disk',
    MPA1953: 'microsoft',
    MPA3025: 'book-open',
    MPA1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
  },
  MPE: {
    MPE1000: 'audio-92',
    MPE1039: 'camera-20',
    MPE1051: 'mobile-camera',
    MPE1071: 'food-dog',
    MPE1132: 'bear-2',
    MPE1144: 'controller-2',
    MPE1168: 'note-03',
    MPE1182: 'guitar',
    MPE1246: 'makeup',
    MPE1276: 'ball-soccer',
    MPE127606: 'settings-tool-67',
    MPE1367: 'palette',
    MPE1384: 'baby',
    MPE1430: 'tshirt-53',
    MPE1459: 'building',
    MPE1499: 'chair',
    MPE1540: 'multiple-11',
    MPE1574: 'armchair',
    MPE1575: 'oven',
    MPE1648: 'tv-2',
    MPE1743: 'car-front',
    MPE1747: 'steering-wheel',
    MPE1953: 'microsoft',
    MPE3025: 'book-open',
    MPE3937: 'watch-circle',
    MPE1512: 'tractor',
    MPE409431: 'hospital-34',
    MPE112701: 'baloon',
    MPE1403: 'chef-hat',
    MPE443829: 'palette',
    EXTRA_SUPERMARKET: 'supermarket',
    MPE1500: 'construction',
  },
  MPT: {
    MPT1000: 'audio-92',
    MPT1039: 'camera-20',
    MPT1051: 'mobile-camera',
    MPT1132: 'bear-2',
    MPT1144: 'controller-2',
    MPT1168: 'note-03',
    MPT1182: 'guitar',
    MPT1276: 'ball-soccer',
    MPT1367: 'palette',
    MPT1430: 'tshirt-53',
    MPT1459: 'building',
    MPT1540: 'multiple-11',
    MPT1574: 'oven',
    MPT1648: 'tv-2',
    MPT1743: 'car-front',
    MPT1747: 'steering-wheel',
    MPT1798: 'disk',
    MPT1953: 'microsoft',
    MPT3025: 'book-open',
    MPT3937: 'watch-circle',
    EXTRA_SUPERMARKET: 'supermarket',
  },
  MPY: {
    MPY1000: 'audio-92',
    MPY1039: 'camera-20',
    MPY1051: 'mobile-camera',
    MPY1132: 'bear-2',
    MPY1144: 'controller-2',
    MPY1168: 'note-03',
    MPY1182: 'guitar',
    MPY1276: 'ball-soccer',
    MPY1367: 'palette',
    MPY1430: 'tshirt-53',
    MPY1459: 'building',
    MPY1540: 'multiple-11',
    MPY1574: 'oven',
    MPY1648: 'tv-2',
    MPY1743: 'car-front',
    MPY1747: 'steering-wheel',
    MPY1798: 'disk',
    MPY1953: 'microsoft',
    MPY3025: 'book-open',
    MPY3937: 'watch-circle',
    MPY1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
  },
  MRD: {
    MRD1000: 'audio-92',
    MRD1039: 'camera-20',
    MRD1051: 'mobile-camera',
    MRD1132: 'bear-2',
    MRD1144: 'controller-2',
    MRD1168: 'note-03',
    MRD1182: 'guitar',
    MRD1276: 'ball-soccer',
    MRD1367: 'palette',
    MRD1430: 'tshirt-53',
    MRD1459: 'building',
    MRD1540: 'multiple-11',
    MRD1574: 'oven',
    MRD1648: 'tv-2',
    MRD1743: 'car-front',
    MRD1747: 'steering-wheel',
    MRD1798: 'disk',
    MRD1953: 'microsoft',
    MRD3025: 'book-open',
    MRD1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
  },
  MSV: {
    MSV1000: 'audio-92',
    MSV1039: 'camera-20',
    MSV1051: 'mobile-camera',
    MSV1132: 'bear-2',
    MSV1144: 'controller-2',
    MSV1168: 'note-03',
    MSV1182: 'guitar',
    MSV1276: 'ball-soccer',
    MSV1367: 'palette',
    MSV1430: 'tshirt-53',
    MSV1459: 'building',
    MSV1540: 'multiple-11',
    MSV1574: 'oven',
    MSV1648: 'tv-2',
    MSV1743: 'car-front',
    MSV1747: 'steering-wheel',
    MSV1798: 'disk',
    MSV1953: 'microsoft',
    MSV3025: 'book-open',
    MSV3937: 'watch-circle',
    MSV1512: 'tractor',
    EXTRA_SUPERMARKET: 'supermarket',
  },
};

/**
 * Module dependencies
 */
const React = require('react');
const { useEffect } = require('react');
const PropTypes = require('prop-types');
const Head = require('nordic/head');
const injectI18n = require('nordic/i18n/injectI18n');
const Script = require('nordic/script');
const Page = require('nordic/page');
const { useCookies } = require('react-cookie');
const TrendsViewDesktop = require('./trendsView.desktop');
const TrendsViewMobile = require('./trendsView.mobile');
const deviceTypes = require('../../../enums/deviceTypes');
const { createMeliDataTracks } = require('../../../utils/trackUtils');

/**
 * TrendsViewMain Component
 */
function TrendsViewMain(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['cookies']);
  const cookieName = 'meliLab';
  const { i18n, translations, lowEnd, deviceType, company, imagesPrefix, jsDisabled, supportWebP } = props;
  let { meliLab } = props;

  useEffect(() => {
    const currentCookieValue = cookies[cookieName];
    meliLab = meliLab === '' ? undefined : meliLab;
    if (currentCookieValue !== meliLab) {
      if (meliLab) {
        setCookie(cookieName, meliLab, { path: '/' });
      } else {
        removeCookie(cookieName);
      }
      // eslint-disable-next-line
      location.reload();
    }
  }, []);

  const namespace = 'ui-view-main';
  const { siteId, categoryId, navigation, increasedSearchGrowthTrends, higherRevenueTrends, shortTailTrends, featuredTrends, locale } = props;

  const preloadedState = {
    i18n,
    translations,
    siteId,
    lowEnd,
    deviceType,
    company,
    imagesPrefix,
    categoryId,
    navigation,
    increasedSearchGrowthTrends,
    higherRevenueTrends,
    shortTailTrends,
    featuredTrends,
    meliLab,
    supportWebP,
    jsDisabled,
    locale,
  };

  const minTrendsForDesktop = 4;
  const minTrendsForMobile = 6;
  const minTrendsAvailable = deviceType === deviceTypes.DESKTOP_TYPE ? minTrendsForDesktop : minTrendsForMobile;

  const hasEnoughTrends = (trendsData) => {
    if (trendsData.trends.length < minTrendsAvailable) {
      return false;
    }
    return true;
  };

  const consolidateUniqueKeywordTrendLists = () => {
    const allTrends = increasedSearchGrowthTrends.trends.concat(higherRevenueTrends.trends).concat(shortTailTrends.trends);
    const uniqueTrends = [
      ...new Map(allTrends.map((item) => [item.keyword, item])).values(),
    ];
    return uniqueTrends;
  };

  const getConslidatedTrendsList = () => {
    let countListWithFewTrends = 0;
    if (!hasEnoughTrends(increasedSearchGrowthTrends)) {
      countListWithFewTrends += 1;
    }
    if (!hasEnoughTrends(higherRevenueTrends)) {
      countListWithFewTrends += 1;
    }
    if (!hasEnoughTrends(shortTailTrends)) {
      countListWithFewTrends += 1;
    }

    if (countListWithFewTrends >= 2) {
      return consolidateUniqueKeywordTrendLists();
    }

    return undefined;
  };

  const data = {
    jsDisabled,
    supportWebP,
    siteId,
    categoryId,
    increasedSearchGrowthTrends: {
      title: i18n.gettext('Las búsquedas que más crecieron'),
      trends: increasedSearchGrowthTrends.trends,
      description: i18n.gettext('MAYOR CRECIMIENTO'),
    },
    higherRevenueTrends: {
      title: i18n.gettext('Las búsquedas más deseadas'),
      trends: higherRevenueTrends.trends,
      description: i18n.gettext('MÁS DESEADA'),
    },
    shortTailTrends: {
      title: i18n.gettext('Las tendencias más populares'),
      trends: shortTailTrends.trends,
      description: i18n.gettext('MÁS POPULAR'),
    },
    featuredTrends: {
      title: i18n.gettext('Tendencias que también te pueden interesar'),
      trends: featuredTrends.trends,
    },
    consolidatedTrends: {
      trends: getConslidatedTrendsList(),
    },
    navigation,
  };

  const subtitle = i18n.gettext('Conoce las más destacadas de la última semana');
  const getHeaderTitle = () => {
    const trends = i18n.gettext('Tendencias');
    const mercadoLibre = i18n.gettext('Mercado Libre');
    if (categoryId) {
      const categoryName = navigation.name;
      return `${trends}: ${categoryName} | ${mercadoLibre}`;
    }

    return `${trends} | ${mercadoLibre}`;
  };

  const headerTitle = getHeaderTitle();

  const title = () => {
    const searchTrends = i18n.gettext('Tendencias de búsqueda en');
    const mercadoLibre = i18n.gettext('Mercado Libre');
    const separator = (deviceType === deviceTypes.MOBILE_TYPE) ? '\n' : ' ';
    return `${searchTrends} ${separator} ${mercadoLibre}`;
  };

  const getDeviceClassName = () => {
    if (deviceType === deviceTypes.MOBILE_TYPE) {
      return 'mobile';
    }
    if (categoryId) {
      return 'desktop-with-menu';
    }
    return 'desktop-without-menu';
  };

  const deviceClassName = getDeviceClassName();

  const metaDescriptionText = i18n.gettext('Conoce las tendencias más destacadas de Mercado Libre. Encuentra miles de marcas y productos a precios increíbles.');

  const meliDataTrack = createMeliDataTracks(props);

  return (
    <Page name="trends" state={preloadedState} melidata={meliDataTrack}>
      <Head>
        <meta name="description" content={metaDescriptionText} />
        <title>{headerTitle}</title>
      </Head>
      <Script src="vendor.js" />
      <div className={`${namespace}-title-container ${namespace}-${deviceClassName}-title-container`}>
        <h1 className={`${namespace}-title ${namespace}-${deviceType}-title`}>
          {title()}
        </h1>
        <p className={`${namespace}-subtitle ${namespace}-${deviceClassName}-subtitle`}>
          {subtitle}
        </p>
      </div>
      <div className={`${namespace}-page-content`}>
        {deviceType === deviceTypes.DESKTOP_TYPE
          ? <TrendsViewDesktop {...data} />
          : <TrendsViewMobile {...data} />}
      </div>
    </Page>
  );
}


TrendsViewMain.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  translations: PropTypes.shape({}),
  meliLab: PropTypes.string,
  jsDisabled: PropTypes.bool.isRequired,
  supportWebP: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
  categoryId: PropTypes.string,
  deviceType: PropTypes.string.isRequired,
  lowEnd: PropTypes.bool.isRequired,
  company: PropTypes.string.isRequired,
  imagesPrefix: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  higherRevenueTrends: PropTypes.shape({
    site_id: PropTypes.string,
    category_id: PropTypes.string,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  shortTailTrends: PropTypes.shape({
    site_id: PropTypes.string,
    category_id: PropTypes.string,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  increasedSearchGrowthTrends: PropTypes.shape({
    site_id: PropTypes.string,
    category_id: PropTypes.string,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }).isRequired,
  featuredTrends: PropTypes.shape({
    site_id: PropTypes.string,
    category_id: PropTypes.string,
    trends: PropTypes.arrayOf(PropTypes.shape({
      keyword: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      category_id: PropTypes.string.isRequired,
      site_id: PropTypes.string.isRequired,
    })),
  }),
  navigation: PropTypes.shape({
    site_id: PropTypes.string.isRequired,
    category_id: PropTypes.string,
    name: PropTypes.string,
    categories: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
    breadcrumb: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })),
  }),
};

TrendsViewMain.defaultProps = {
  translations: {},
};

/**
 * Inject i18n context as props into TrendsViewMain.
 */
module.exports = injectI18n(TrendsViewMain);

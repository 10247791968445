/**
 * Module dependencies
 */
const React = require('react');
const { useState } = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const ChevronDown20 = require('@andes/icons/ChevronDown20');
const ChevronUp20 = require('@andes/icons/ChevronUp20');
const Card = require('@andes/card');
const { CardHeader, CardContent } = require('@andes/card');
const SearchEntry = require('../searchEntry/searchEntry');
const DivideByGroupsOf = require('../../../../../utils/divide-groups');
const DeviceTypes = require('../../../../../enums/deviceTypes');
const stringUtils = require('../../../../../utils/stringUtils');
/**
 * SearchEntryExpandable Component
 */
function SearchEntryExpandable(props) {
  const namespace = 'ui-search-container-expandable';
  const { i18n, trends, title, description, jsDisabled, supportWebP, preload } = props;

  const deviceType = DeviceTypes.MOBILE_TYPE;

  const columnsCount = 2;
  const countRowShowedByDefault = 3;

  const defaultSearchesShowed = 6;

  const moreSearchesText = i18n.gettext('Ver más');
  const lessSearchesText = i18n.gettext('Ver menos');

  const [showAllSearches, setShowAllSearches] = useState(false);

  const trendGroups = DivideByGroupsOf(trends, columnsCount);

  const calculateSearchEntryIndex = (rowIndex, columnIndex) => rowIndex * columnsCount + columnIndex;

  const titleKey = stringUtils.replaceBlanksWithSlash(title);
  const renderSearchEntryContent = (searchEntry, index, display) => {
    const className = `search-entry-wrapper-cell ${display ? '' : 'not-display'}`;
    return (
      <div key={`${titleKey}-${index}`} className={className}>
        <SearchEntry
          key={`search-entry-${titleKey}-${index}`}
          searchEntry={searchEntry}
          position={index}
          deviceType={deviceType}
          description={description}
          jsDisabled={jsDisabled}
          showImage={display}
          preload={preload}
          supportWebP={supportWebP}
        />
      </div>
    );
  };

  const renderSearchEntry = (searchEntryGroup, rowIndex) => {
    const display = showAllSearches || rowIndex < countRowShowedByDefault;
    return (
      <div className="search-entry-wrapper" key={`search-entry-wrapper-${titleKey}-${rowIndex}`}>
        {searchEntryGroup.elements.map((searchEntry, columnIndex) => renderSearchEntryContent(searchEntry, calculateSearchEntryIndex(rowIndex, columnIndex), display))}
      </div>
    );
  };

  const handleShowHideSearchesClick = () => {
    setShowAllSearches(!showAllSearches);
  };

  const renderButtonContent = () => {
    if (showAllSearches) {
      return (
        <>
          {lessSearchesText}
          <ChevronUp20 />
        </>
      );
    }

    return (
      <>
        {moreSearchesText}
        <ChevronDown20 />
      </>
    );
  };

  const renderShowMoreButton = () => {
    if (trends.length <= defaultSearchesShowed) {
      return <></>;
    }

    return (
      <button className="section-button" onClick={handleShowHideSearchesClick}>
        {renderButtonContent()}
      </button>
    );
  };

  return (
    <section className={`${namespace}`}>
      <Card paddingSize={0} className="container">
        <CardHeader className="section-header">
          <h2 className="section-header__title">{title}</h2>
        </CardHeader>
        <CardContent>
          <div className="wrapper">
            {trendGroups.map((searchEntryGroup, index) => (
              renderSearchEntry(searchEntryGroup, index)
            ))}
          </div>
          {renderShowMoreButton()}
        </CardContent>
      </Card>

    </section>
  );
}

SearchEntryExpandable.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  trends: PropTypes.arrayOf(PropTypes.shape({
    keyword: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    category_id: PropTypes.string.isRequired,
    site_id: PropTypes.string.isRequired,
  })),
  description: PropTypes.string,
  jsDisabled: PropTypes.bool,
  supportWebP: PropTypes.bool.isRequired,
  preload: PropTypes.bool.isRequired,
};

/**
 * Inject i18n context as props into SearchEntryExpandable.
 */
module.exports = injectI18n(SearchEntryExpandable);

/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const Card = require('@andes/card');
const { CardContent } = require('@andes/card');
const Thumbnail = require('@andes/thumbnail');
const { Image } = require('nordic/image');
const deviceTypes = require('../../../../../enums/deviceTypes');
const stringUtils = require('../../../../../utils/stringUtils');
/**
 * SearchEntry Component
 */
function SearchEntry(props) {
  const namespace = 'ui-search-entry';
  const { i18n, searchEntry, position, categoryId, deviceType, description, insideCarousel, jsDisabled, showImage, preload, supportWebP } = props;
  const fragment = '#trend';
  const countImagesForDevice = deviceType === deviceTypes.MOBILE_TYPE ? 2 : 3;

  const translateDescription = description !== undefined ? description : null;

  const getCardContainerClass = () => {
    if (deviceType === deviceTypes.MOBILE_TYPE) {
      return 'mobile-container';
    }
    if (categoryId) {
      return 'desktop-with-menu-container';
    }
    return 'desktop-without-menu-container';
  };

  const addFragmentToUrl = () => {
    if (!searchEntry.url.includes(fragment)) {
      searchEntry.url = searchEntry.url.concat(fragment);
    }
  };

  addFragmentToUrl();

  const fallBackImage = [];
  const updateImageUrl = () => {
    for (let i = 0; i < searchEntry.images.length; i += 1) {
      fallBackImage.push(searchEntry.images[i]);
      let url = searchEntry.images[i]
        .replace('http://', 'https://')
        .replace(/-[a-zA-Z].jpg/, '-I.jpg')
        .replace(/ml[a-zA-Z]-s[0-9]-p.mlstatic.com[/]/, 'http2.mlstatic.com/D_Q_NP_');
      if (supportWebP) {
        url = url.replace('.jpg', '.webp');
      }

      searchEntry.images[i] = url;
    }
  };
  updateImageUrl();

  const duplicateImageIfNeeded = () => {
    const urlToDuplicate = searchEntry.images[0];
    const fallbackUrlToDuplicate = fallBackImage[0];
    while (searchEntry.images.length < countImagesForDevice) {
      searchEntry.images.push(urlToDuplicate);
      fallBackImage.push(fallbackUrlToDuplicate);
    }
  };
  duplicateImageIfNeeded();

  const imageAltValues = [];
  const imageKeyValues = [];
  const populateAltArray = () => {
    const imageForSearchText = i18n.gettext('imagen para búsqueda de');
    const firstText = i18n.gettext('Primera');
    const secondText = i18n.gettext('Segunda');
    const thirdText = i18n.gettext('Tercera');
    imageAltValues.push(`${firstText} ${imageForSearchText} ${searchEntry.keyword}`);
    imageAltValues.push(`${secondText} ${imageForSearchText} ${searchEntry.keyword}`);
    imageAltValues.push(`${thirdText} ${imageForSearchText} ${searchEntry.keyword}`);

    imageKeyValues.push(stringUtils.replaceBlanksWithSlash(imageAltValues[0]));
    imageKeyValues.push(stringUtils.replaceBlanksWithSlash(imageAltValues[1]));
    imageKeyValues.push(stringUtils.replaceBlanksWithSlash(imageAltValues[2]));
  };
  populateAltArray();

  const hasMoreImagesToShow = (index) => index + 1 <= countImagesForDevice;

  const onload = (() => {
    if (jsDisabled) {
      return 'off';
    }
    return !(preload && showImage) ? 'on' : 'off';
  })();

  return (
    <a href={searchEntry.url} className={`${namespace}-container`}>
      <Card paddingSize={0} className={`${namespace}-${getCardContainerClass()}`}>
        <CardContent>
          <div className={`${namespace}-images-wrapper`}>
            <div className={`${namespace}-images-container`}>
              { searchEntry.images.map((image, index) => (hasMoreImagesToShow(index)
                ? (
                  <Thumbnail key={imageKeyValues[`${index}`]} className={`${namespace}-thumbnail ${namespace}-thumbnail-${deviceType}-image${index}`}>
                    { insideCarousel
                      ? (<Image onload="on" className={`${namespace}-thumbnail-img`} mounted="true" carousel alt={imageAltValues[index]} src={image} srcFallback={fallBackImage[index]} width="60" height="60" />)
                      : (<Image lazyload="off" preload={preload && showImage} onload={onload} className={`${namespace}-thumbnail-img`} alt={imageAltValues[index]} src={image} srcFallback={fallBackImage[index]} width="60" height="60" />)}
                  </Thumbnail>)
                : null
              ))}
            </div>
          </div>
          { description !== undefined ? <div className={`${namespace}-description`}>{`${position + 1}º ${translateDescription}`}</div> : null }
          <h3 className={`${namespace}-keyword`}>{stringUtils.capitalizeFirstLetter(searchEntry.keyword)}</h3>
        </CardContent>
      </Card>
    </a>
  );
}

SearchEntry.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  position: PropTypes.number.isRequired,
  searchEntry: PropTypes.shape({
    keyword: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    category_id: PropTypes.string.isRequired,
    site_id: PropTypes.string.isRequired,
  }),
  categoryId: PropTypes.string,
  deviceType: PropTypes.string.isRequired,
  description: PropTypes.string,
  insideCarousel: PropTypes.bool,
  jsDisabled: PropTypes.bool,
  showImage: PropTypes.bool,
  supportWebP: PropTypes.bool.isRequired,
  preload: PropTypes.bool,
};

SearchEntry.defaultProps = {
  insideCarousel: false,
  showImage: true,
};

/**
 * Inject i18n context as props into SearchEntry.
 */
module.exports = injectI18n(SearchEntry);

/**
 * Service interface
 */
class Service {
  static replaceBlanksWithSlash = (str) => (this.replaceBlanks(str, '-'));

  static replaceBlanks = (str, value) => (str.replace(/ /g, value));

  static capitalizeFirstLetter = (str) => str[0].toUpperCase() + str.slice(1);
}
/**
 * Expose Service
 */
module.exports = Service;
